<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col xl="6" lg="8" md="8" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Tarif par établissement" />
        </div>

        <!-- la barre de progression -->
        <v-row justify="center">
          <v-col xl="6" lg="8" md="8" sm="10" xs="12">
            <!-- la progess bar à afficher lors du chargement des données -->
            <v-progress-linear
              indeterminate
              :active="loading"
            ></v-progress-linear>
          </v-col>
        </v-row>

        <!-- le détail du service -->
        <DetailViewBoxComponent 
          title="Détail d'un tarif pour un établissement">
          <template v-slot:items>
            <InputFieldComponent label="Établissement" :value="establishmentName" />
            <InputFieldComponent label="Produit" :value="productName" />
            <!-- <InputFieldComponent label="Nom" :value="name" />
            <InputFieldComponent label="Description" :value="description" /> -->
            <InputFieldComponent label="Type de tarif" :value="priceTypeName" />
            
            <!-- Les montant -->
            <InputFieldComponent label="Montant(s)" alignLabel="start">
              <template v-slot:value>
                <div v-for="(amount, i) in amounts" :key="i">
                  {{ amount.toString() }}
                </div>
              </template>
            </InputFieldComponent>  

            <InputFieldComponent label="Unité de tarif" :value="priceUnitName" :divider="false" />
          </template>
        </DetailViewBoxComponent>
      </v-col>
    </v-row>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar>

  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DetailViewBoxComponent from "@/components/ui/DetailViewBoxComponent.vue";
import InputFieldComponent from "@/components/ui/InputFieldComponent.vue";

import { ProductPriceEstablishmentService } from "@/service/conf/product_price_establishment_service.js"
import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { AvecProductsService } from "@/service/conf/avec_products_service.js";
import { PriceTypesService } from "@/service/conf/price_types_service.js"
import { PriceUnitsService } from "@/service/conf/price_units_service.js"

import { numberWithMinTwoDecimal, numberToString } from "@/tools/number_tool.js";
import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";


export default {
  name: "DetailsPriceEstablishment",
  components: { Workflow, TitleAndReturnComponent, DetailViewBoxComponent, InputFieldComponent },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin,],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service de la vue */
      service: null,
      serviceEstablishment: null,
      serviceProduct: null,
      servicePriceTypes: null,
      servicePricesUnits: null,

      /**l'identifiant de l'entité à visualiser. */
      entityId: null,

      establishmentName: null,
      productName: null,
      name: null,
      description: null,
      priceTypeName: null,
      priceUnitName: null,
      amounts: [],
      

      /**Afficher le bouton de retour */
      showBackButton: false,

    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;
        // Récupération de l'entité
        let entity = await this.service.getById(this.entityId);
        
        // Récupération de l'établissement associé
        let estab = await this.serviceEstablishment.getById(entity.establishmentId);
        // Récupération du produit associé
        let prod = await this.serviceProduct.getById(entity.productId);
        // Récupération du type de tarif
        let type = await this.servicePriceTypes.getById(entity.priceType);
        //Récupération de l'unité du tarif
        let unit = await this.servicePricesUnits.getById(entity.priceUnit);
      
        /** Initialisation des datas */
        this.establishmentName = estab.digitalName;
        this.productName = prod.digitalName;
        this.name = entity.name;
        this.description = entity.description;
        this.priceTypeName = type.label;
        this.priceUnitName = unit.label;

        this.amounts = [];
        for (let amount of entity.amounts) {
          let price = numberWithMinTwoDecimal(amount);
          price = numberToString(price);
          this.amounts.push(price);
        }

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {},
  mounted() {
    // Instanciation des services
    this.service = new ProductPriceEstablishmentService(this.$api.getProductPricesEstablishment());
    this.serviceEstablishment = new EstablishmentsService(this.$api);
    this.serviceProduct = new AvecProductsService(this.$api.getTransmissionsAvecProductsApi());
    this.servicePriceTypes = new PriceTypesService(this.$api.getPriceTypesApi());
    this.servicePricesUnits = new PriceUnitsService(this.$api.getPriceUnitsApi());

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    //on affiche le bouton retour
    this.showBackButton = true;

    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>